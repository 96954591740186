<template>
  <div>
    <div class="punch-card-btn">
      <div class="btn" @click="punchCardClick()">打卡</div>
    </div>
    <van-popup
      v-model="showNFCPopupInner"
      class="nfc-popup"
      position="bottom"
      closeable
      close-icon-position="top-left"
      @close="popupClose()"
    >
      <div class="title-box">打卡</div>
      <div class="nfc-tips-box">
        <img
          src="../../../assets/images/nfc-tips.png"
          alt="nfc-tips"
          width="256"
          height="256"
        />
        <p>请将手机靠近打卡点</p>
      </div>
    </van-popup>
  </div>
</template>

<script>
export default {
  name: "PunchCard",
  components: {},
  model: {
    prop: "",
    event: ""
  },
  props: {
    showNFCPopup: {
      type: Boolean,
      default: false
    }
    // value: {
    //   type: Boolean,
    //   default: false
    // }
  },
  data() {
    return {
      showNFCPopupInner: false
    };
  },
  computed: {},
  watch: {
    showNFCPopup(newV) {
      this.showNFCPopupInner = newV;
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {
    // this.showNFCPopupInner = this.showNFCPopup;
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  methods: {
    punchCardClick() {
      this.$emit("click");
    },
    popupClose() {
      this.$emit("close");
    }
  }
};
</script>

<style lang="scss" scoped>
.punch-card-btn {
  position: fixed;
  bottom: 20px;
  // left: calc(50% - 32px);
  left: 50%;
  transform: translateX(-32px);
  .btn {
    width: 64px;
    height: 64px;
    line-height: 64px;
    text-align: center;
    background: #1271ff;
    border-radius: 50%;
    box-shadow: 0px 5px 12px 0px rgba(30, 120, 255, 0.6);
    font-size: 16px;
    font-weight: 500;
    color: #ffffff;
  }
}
.nfc-popup {
  height: 100vh;
  .title-box {
    height: 44px;
    line-height: 44px;
    padding: 6px 0 0;
    text-align: center;
    font-size: 18px;
    font-weight: 400;
    color: #2e2e4d;
  }
  .nfc-tips-box {
    text-align: center;
    // padding: 61px 61px 0 58px;
    padding: 61px 0 0;
    p {
      padding: 65px 0 0;
      font-size: 18px;
      font-weight: 400;
      color: #2e2e4d;
    }
  }
}
</style>
