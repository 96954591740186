<template>
  <div>
    <van-nav-bar
      title="巡检任务"
      left-arrow
      :border="false"
      fixed
      :right-text="curPersonAuth ? navBarRightText : ''"
      @click-left="$router.go(-1)"
      @click-right="onClickRight"
    />
    <div class="content">
      <div class="title-box">
        <div class="left-box">
          <span class="title">{{ pageInfo.name }}</span>
          <span v-if="pageInfo.overtimeStatus" class="overtime-tag">超期</span>
          <span v-if="pageInfo.supplementFlag" class="make-up-tag">补录</span>
        </div>
        <div class="status-box" :class="statusClassSwitch">
          {{ pageInfo.recordStatusName }}
        </div>
      </div>
      <span class="date-box">{{
        `${pageInfo.validStartTime || "--:--:--"} - ${pageInfo.validEndTime ||
          "--:--:--"} ${pageInfo.patrolEndTimeFlagName}`
      }}</span>
      <div class="detail-box" :class="{ 'pack-up': baseInfoPackUp }">
        <van-form ref="baseForm" class="base-form">
          <div class="detail-item">
            <span class="label">所属部门：</span>
            <span class="text">{{ pageInfo.departmentName }}</span>
          </div>
          <!-- <div class="detail-item">
            <span class="label">执行班组：</span>
            <span class="text">{{ pageInfo.shiftGroupName }}</span>
          </div> -->
          <div class="detail-item">
            <span class="label">任务类型：</span>
            <span class="text">{{ pageInfo.taskTypeName }}</span>
          </div>
          <div class="detail-item">
            <span class="label">巡检周期：</span>
            <span class="text">{{ cycleUnintsSwitch }}</span>
          </div>
          <template v-if="pageInfo.recordStatus !== 0">
            <div class="detail-item">
              <span class="label">开始时间：</span>
              <span class="text">{{
                pageInfo.actualStartTime || "--:--:--"
              }}</span>
            </div>
            <div class="detail-item">
              <span class="label">结束时间：</span>
              <span class="text">{{
                pageInfo.actualEndTime || "--:--:--"
              }}</span>
            </div>
            <div class="detail-item">
              <span class="label">执行人：</span>
              <span class="text executor">{{
                pageInfo.executePersonName
              }}</span>
              <span class="switch-account" @click="switchAccountClick"
                >切换账号</span
              >
            </div>
            <div class="detail-item">
              <span class="label">同行人：</span>
              <span class="text associate">
                <!-- :value="" -->
                <!-- @click="associatePopupVisible = true"  is-link -->
                <van-field
                  readonly
                  arrow-direction="down"
                  @click="associateClick()"
                >
                  <template #input>
                    {{
                      pageInfo.partnerName
                        ? pageInfo.partnerName.replace(/,/g, "、")
                        : ""
                    }}
                  </template>
                </van-field>
              </span>
            </div>
          </template>
          <template v-if="pageInfo.supplementFlag === 1">
            <div v-if="pageInfo.recordStatus === 2" class="detail-item">
              <span class="label">补录时间：</span>
              <span class="text">{{
                pageInfo.supplementTime || "--:--:--"
              }}</span>
            </div>
            <!-- <div class="detail-item"> -->
            <van-field
              class="c-van-field"
              name="supplementFileList"
              required
              label="纸质检查表照片："
              label-width="120px"
              label-class="c-label"
              :border="false"
              :rules="[{ required: true, message: '请补充纸质照片' }]"
            >
              <template #input>
                <van-uploader
                  v-model="pageInfo.supplementFileList"
                  multiple
                  :max-count="6"
                  :max-size="1024 * 1024 * 10"
                  accept="image/*"
                  upload-icon="plus"
                  upload-text="上传照片"
                  :deletable="pageInfo.recordStatus === 1"
                  :show-upload="pageInfo.recordStatus === 1"
                  :after-read="afterRead"
                />
              </template>
            </van-field>
            <!-- </div> -->
            <!-- <div class="detail-item">
              <span class="label">实际开始时间：</span>
              <date-time-picker-popup
                v-if="pageInfo.recordStatus === 1"
                v-model="pageInfo.startTime"
                type="datetime"
                :time-close="false"
                format="{y}-{m}-{d} {h}:{i}:00"
              ></date-time-picker-popup>
              <span class="text">{{ pageInfo.startTime || "--:--:--" }}</span>
            </div> -->
            <van-field
              class="c-van-field"
              readonly
              name="datetimePicker"
              :value="pageInfo.actualStartTime"
              required
              label="实际开始时间："
              placeholder="点击选择时间"
              label-width="100px"
              label-class="c-label"
              :border="false"
              :rules="[{ required: true, message: '请补充实际开始时间' }]"
            >
              <template #input>
                <date-time-picker-popup
                  v-if="pageInfo.recordStatus === 1"
                  v-model="pageInfo.actualStartTime"
                  class="c-data-tiem"
                  type="datetime"
                  :time-close="false"
                  format="{y}-{m}-{d} {h}:{i}:00"
                ></date-time-picker-popup>
              </template>
            </van-field>
            <!-- <div class="detail-item">
              <span class="label">实际结束时间：</span>
              <date-time-picker-popup
                v-if="pageInfo.recordStatus === 1"
                v-model="pageInfo.endTime"
                type="datetime"
                :time-close="false"
                format="{y}-{m}-{d} {h}:{i}:00"
              ></date-time-picker-popup>
              <span class="text">{{ pageInfo.endTime || "--:--:--" }}</span>
            </div> -->
            <van-field
              class="c-van-field"
              readonly
              name="datetimePicker"
              :value="pageInfo.actualEndTime"
              required
              label="实际结束时间："
              placeholder="点击选择时间"
              label-width="100px"
              label-class="c-label"
              :border="false"
              :rules="[{ required: true, message: '请补充实际结束时间' }]"
            >
              <template #input>
                <date-time-picker-popup
                  v-if="pageInfo.recordStatus === 1"
                  v-model="pageInfo.actualEndTime"
                  class="c-data-tiem"
                  type="datetime"
                  :time-close="false"
                  format="{y}-{m}-{d} {h}:{i}:00"
                ></date-time-picker-popup>
              </template>
            </van-field>
          </template>
        </van-form>
      </div>
      <div v-if="pageInfo.recordStatus !== 0" class="exp-box">
        <van-icon
          v-show="baseInfoPackUp"
          name="arrow-down"
          size="18"
          color="#ccc"
          @click="baseInfoPackUp = false"
        />
        <van-icon
          v-show="!baseInfoPackUp"
          name="arrow-up"
          size="18"
          color="#ccc"
          @click="baseInfoPackUp = true"
        />
      </div>
    </div>
    <section class="list-box">
      <div v-for="(item, idx) of taskList" :key="item.id" class="">
        <div class="item" @click="toDetail(item)">
          <div class="index-box">
            <div class="path-box">
              <span
                v-if="pageInfo.sortedStatus && idx !== 0"
                class="path"
                :class="{ 'checked-path': topPathSwitch(idx) }"
              ></span>
            </div>
            <div
              class="index-radius"
              :class="{
                'checked-radius': item.checked,
                'current-radius': pageInfo.sortedStatus && item.current
              }"
            >
              {{ idx + 1 }}
            </div>
            <div class="path-box">
              <span
                v-if="pageInfo.sortedStatus && idx !== taskList.length - 1"
                class="path"
                :class="{ 'checked-path': item.checked }"
              ></span>
            </div>
          </div>
          <div class="task-info-box">
            <div class="name-box">
              <span class="name">{{ item.pointName }}</span>
              <span>
                <van-icon
                  name="iconfont-Line"
                  class-prefix="iconfont"
                  class="iconfont-Line"
                  size="14"
                />
              </span>
            </div>
            <div class="date-box">
              <span v-if="item.startTime">{{
                `${item.startTime || "--:--:--"} - ${item.endTime ||
                  "--:--:--"}`
              }}</span>
              <span v-else>--</span>
            </div>
            <div class="item-num-box">
              <div class="total num-style">
                总检查项：<span class="item-num">{{ item.totalNum }}</span>
              </div>
              <div class="normal num-style">
                正常：<span class="item-num">{{
                  checkItemNumSwitch(item.normalNum, item.startTime)
                }}</span>
              </div>
              <div
                class="abnormal num-style"
                :class="{
                  active:
                    item.startTime &&
                    item.abnormityNum &&
                    item.abnormityNum !== '0'
                }"
              >
                <label>
                  异常：<span class="item-num">{{
                    checkItemNumSwitch(item.abnormityNum, item.startTime)
                  }}</span>
                </label>
              </div>
              <div
                class="leakage num-style"
                :class="{
                  active: item.startTime && item.leakNum && item.leakNum !== '0'
                }"
              >
                <label>
                  漏检：<span class="item-num">{{
                    checkItemNumSwitch(item.leakNum, item.startTime)
                  }}</span>
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="item-path">
          <span
            v-if="pageInfo.sortedStatus && idx !== taskList.length - 1"
            class="path"
            :class="{ 'checked-path': item.checked }"
          ></span>
        </div>
      </div>
    </section>
    <punch-card
      v-if="
        pageInfo.recordStatus === 1 &&
          endTimeSwitch &&
          curPersonAuth &&
          pageInfo.supplementFlag === 0
      "
      :show-n-f-c-popup="showNFCPopup"
      :is-sort-sign="pageInfo.sortedStatus == 1"
      :record-id="pageInfo.id"
      @click="punchCardClick()"
      @close="punchCardClose()"
      @getFileInfo="getFileInfo"
    />
    <!-- 测试明确去掉补录逻辑 -->
    <!--    <div-->
    <!--      v-if="-->
    <!--        pageInfo.recordStatus === 3 &&-->
    <!--          +pageInfo.timeoutApprove === 1 &&-->
    <!--          curPersonAuth-->
    <!--      "-->
    <!--      class="make-up-box"-->
    <!--    >-->
    <!--      <span class="btn" @click="makeUpClick()">补录</span>-->
    <!--    </div>-->
    <!-- <SelectPopupGrade
      @change="associatePopupChange"
      :value="pageInfo.partner"
      :immediately="false"
      :multi="true"
      listKey="value"
      title="添加同行人（多选）"
      :list.sync="associateDataList"
      @close="associatePopupVisible = false"
      :visible="associatePopupVisible"
    /> -->
    <select-person
      title="同行人(多选)"
      :visible="associatePopupVisible"
      :choose-value="personChooseSwitch"
      :show-department-name="false"
      :show-phone-number="true"
      position="bottom"
      :multiple="true"
      @close="associatePopupVisible = false"
      @confirm="associatePopupConfirm"
    />
    <timeout-reason-dialog
      :id="$route.params.id"
      v-model="visible"
      @close="visible = false"
      @success="onSuccess"
    />
    <InspectCodesTip ref="codeTip"></InspectCodesTip>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import axios from "@/utils/axios";
import {
  getTaskInfoData,
  startTask,
  validTaskComplete,
  completeTask,
  NFCPunchCardSign,
  QRCodePunchCardSign,
  recordSupplement,
  uploadGeo,
  supplementEnd,
  onSignInvalid,
  getTaskPointData,
  getTaskPointInfoList
} from "@/api/psm/inspectionNew";
import selectPerson from "@/components/SelectPerson";
import punchCard from "./components/punchCard";
import TimeoutReasonDialog from "@/views/pointCheckManagement/components/TimeoutReasonDialog";
import InspectCodesTip from "@/views/inspectionNew/components/InspectCodesTip";
import { Toast } from "vant";

export default {
  name: "PointCheckTaskDetail",
  components: {
    TimeoutReasonDialog,
    selectPerson,
    punchCard,
    InspectCodesTip
  },
  data() {
    return {
      visible: false,
      pageInfo: {
        id: "",
        patrolTaskId: "",
        name: "",
        department: "",
        departmentName: "",
        taskType: "",
        taskTypeName: "",
        shiftGroupId: "",
        shiftGroupName: "",
        executePersonId: "",
        executePersonName: "",
        validStartTime: "",
        validEndTime: "",
        actualStartTime: "",
        actualEndTime: "",
        partner: "",
        partnerName: "",
        cycleUnints: "",
        sortedStatus: "",
        recordStatus: "",
        recordStatusName: "",
        overtimeStatus: "",
        overtimeStatusName: "",
        patrolEndTimeFlag: "",
        patrolEndTimeFlagName: "",
        pointInfoList: [
          // {
          //   pointId: "123",
          //   pointName: "",
          //   startTime: "",
          //   endTime: "",
          //   totalNum: "",
          //   normalNum: "",
          //   abnormityNum: "",
          //   leakNum: ""
          // }
        ]
      },
      associateDataList: [
        {
          value: 1,
          label: "123"
        },
        {
          value: 2,
          label: "444"
        },
        {
          value: 3,
          label: "555"
        }
      ],
      associatePopupVisible: false,
      taskList: [],
      showNFCPopup: false,
      baseInfoPackUp: true
    };
  },
  computed: {
    ...mapState({
      userInfo: state => state.login.userInfo
    }),
    navBarRightText() {
      const obj = {
        0: "开始巡检",
        1: "完成巡检"
      };
      return obj[this.pageInfo.recordStatus] || "";
    },
    statusClassSwitch() {
      const obj = {
        0: "weikaishi",
        1: "jinxingzhong",
        2: "yiwancheng",
        3: "yiguoqi"
      };
      const statusClassName = obj[this.pageInfo.recordStatus] || "";
      return statusClassName ? `status-box-${statusClassName}` : "";
    },
    personChooseSwitch() {
      if (!this.pageInfo.partner) return [];
      const names = this.pageInfo.partnerName.split(",");
      const ids = this.pageInfo.partner.split(",");
      return ids.map((item, idx) => {
        return {
          value: item,
          label: names[idx]
        };
      });
    },
    cycleUnintsSwitch() {
      const obj = {
        1: "日",
        2: "周",
        3: "月"
      };
      return obj[this.pageInfo.cycleUnints];
    },
    curPersonAuth() {
      return (
        !this.pageInfo.executePersonId ||
        this.pageInfo.executePersonId === this.userInfo.id
      );
    },
    endTimeSwitch() {
      return this.taskList.some(i => !i.endTime);
    }
  },
  watch: {},
  beforeRouteLeave(to, from, next) {
    // console.log("route", to, from, next);
    if (to.name !== "pointCheckTaskProject") {
      this.setPunchCardSuccess(false);
      this.punchCardClose();
    }
    next();
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {
    // this.setRedirectURL("");
    this.setPunchCardSuccess(false);
    this.getTaskData();
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeDestroy() {
    window.QRCodeCallback = null;
    //   this.setPunchCardSuccess(false);
    //   this.punchCardClose();
    // 清空计时器
    this.clearLocalInterrelated();
  },
  methods: {
    ...mapMutations({
      setPunchCardSuccess: "setPunchCardSuccess"
      // setRedirectURL: "setRedirectURL"
    }),
    ...mapActions(["logout"]),
    // 补偿打卡
    async signCardBak(fileInfo, pointResultId) {
      const params = {
        executePersonId: this.userInfo.id,
        orgCode: this.userInfo.orgCode,
        recordId: this.pageInfo.id,
        pointResultId, // 巡检点id
        invalid: 1,
        fileList: [fileInfo]
      };
      // 补偿打卡
      const data = await onSignInvalid(params);
      if (data?.isOverTime === 1) {
        return Toast("任务超期了");
      }
      const message = `<span>${data.pointName}</span><span style='float: right;'>${data.signTime}</span>`;
      this.$dialog
        .confirm({
          className: "custom-confirm-dialog",
          title: "打卡成功",
          message,
          messageAlign: "left",
          confirmButtonText: "确定",
          confirmButtonColor: "#1676ff",
          showCancelButton: false
        })
        .then(() => {
          this.setPunchCardSuccess(true);
          this.showNFCPopup = false;
          this.$router.push({
            name: "taskProject",
            params: {
              recordId: this.pageInfo.id,
              pointId: data.pointId,
              status: 1,
              signType: this.pageInfo.signType
            }
          });
        })
        .catch(() => {
          // on cancel
        });
    },
    getFileInfo(fileInfo, recordId) {
      this.signCardBak(fileInfo, recordId);
    },
    uploadLocalGeo() {
      // this.clearLocalInterrelated();
      if (this.pageInfo.recordStatus === 1) {
        if (!window.android || !window.android.getLocation) {
          // this.$toast.fail("此版本不支持巡检点路径监控");
          return;
        }
        this.clearLocalInterrelated();
        window.localGeoCallBack = async (location, time, status) => {
          if (status === "1") {
            const date = new Date()
              .toLocaleDateString("zh-cn", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit"
              })
              .replace(/\//g, "-");
            const time = new Date().toLocaleTimeString("en-GB", {
              hour12: false
            });
            const localArr = location.split(",");
            const params = {
              // patrolRecordId: this.pageInfo.id,
              executePersonId: this.userInfo.id,
              longitude: localArr[0],
              latitude: localArr[1],
              uploadTime: `${date} ${time}`
            };
            await uploadGeo(params);
            // android那边获取经纬度的回调之后, 再继续获取新的位置
            window.localGeoFunc && window.localGeoFunc();
          }
          // android那边获取经纬度的回调之后, 再继续获取新的位置
          window.localGeoFunc && window.localGeoFunc();
        };
        window.localGeoFunc = () => {
          window.localGeoTimeout = setTimeout(() => {
            window.androidH5.getLocation("localGeoCallBack");
          }, 5000);
        };
        window.localGeoFunc();
      }
    },
    clearLocalInterrelated() {
      window.localGeoFunc = null;
      window.localGeoCallBack = null;
      clearTimeout(window.localGeoTimeout);
      window.localGeoTimeout = null;
    },
    async getTaskData() {
      const info = await getTaskInfoData(this.$route.params.id);
      const pointInfoList = await getTaskPointInfoList({
        id: this.$route.params.id
      });
      info.pointInfoList = pointInfoList || [];
      info.supplementFileList = (info.supplementFileList || []).map(item => {
        item.url = this.getFileUrl(item.id, false);
        item.isImage = true;
        return item;
      });
      info.pointInfoList?.forEach(item => {
        item.checked = item.startTime ? true : false;
      });
      const findIdx = info.pointInfoList?.findIndex(item => !item.checked);
      if (findIdx !== -1) {
        info.pointInfoList[findIdx].current = true;
      }
      this.pageInfo = info;
      // this.taskList = info.pointInfoList;
      // patrolRecordId
      this.taskList = [
        {
          pointName: info.patrolObjectName,
          ...(info?.pointInfoList?.[0] || {})
          // pointId: info?.pointInfoList?.[0]?.pointId || "",
          // totalNum: info.totalNum,
          // normalNum: info.normalNum,
          // abnormityNum: info.abnormityNum
        }
      ];
      this.uploadLocalGeo();
    },
    onClickRight() {
      const obj = {
        0: "startInspection",
        1: "completeInspection"
      };
      const prop = obj[this.pageInfo.recordStatus] || "";
      prop && this[prop]();
    },
    startInspection() {
      // const now = new Date();
      // const startTime = new Date(this.pageInfo.validStartTime);
      // const endTime = new Date(this.pageInfo.validEndTime);
      // if (now < startTime) {
      //   this.$toast.fail("任务未开始，请等待");
      //   return;
      // }
      // if (now > endTime) {
      //   this.$toast.fail("任务已过期");
      //   // this.getTaskData();
      //   return;
      // }
      this.associatePopupVisible = true;
    },
    async completeInspection() {
      if (this.pageInfo.supplementFlag === 1) {
        this.baseInfoPackUp = false;
        await this.$refs.baseForm.validate();
        this.$dialog
          .confirm({
            className: "custom-confirm-dialog",
            title: "提醒",
            message: "该巡检已经完成",
            messageAlign: "left",
            confirmButtonText: "确定",
            confirmButtonColor: "#1676ff"
          })
          .then(async () => {
            const params = {
              startTime: this.pageInfo.actualStartTime,
              endTime: this.pageInfo.actualEndTime,
              fileList: this.pageInfo.supplementFileList,
              recordId: this.pageInfo.id,
              partner: this.pageInfo.partner,
              executePersonId: this.userInfo.id
            };
            await supplementEnd(params);
            this.clearLocalInterrelated();
            this.$router.go(-1);
          })
          .catch(() => {
            // on cancel
          });
        return;
      }
      const validRes = await validTaskComplete(this.pageInfo.id);
      this.$dialog
        .confirm({
          className: "custom-confirm-dialog",
          title: "提醒",
          message: validRes,
          messageAlign: "left",
          confirmButtonText: "确定",
          confirmButtonColor: "#1676ff"
        })
        .then(async () => {
          // await completeTask(this.pageInfo.id);
          // this.clearLocalInterrelated();
          // this.$router.go(-1);
          const res = await completeTask(this.pageInfo.id);
          if (res?.endStatus === 4) {
            this.visible = true;
          } else {
            this.clearLocalInterrelated();
            this.$router.go(-1);
          }
        })
        .catch(() => {
          // on cancel
        });
    },
    onSuccess() {
      this.clearLocalInterrelated();
      this.$router.go(-1);
    },
    // async associatePopupChange(val) {
    //   console.log("val", val);
    //   this.pageInfo.partner = val.map(item => item.value);
    //   this.pageInfo.partnerName = val.map(item => item.label);
    //   // if (this.pageInfo.recordStatus === 1) {
    //   // 请求接口保存人员，再拉取数据；进行中可以变更人。
    //   // }
    //   try {
    //     personIds = this.pageInfo.partner.toString();
    //     await startTask(this.pageInfo.id, personIds);
    //     this.getTaskData();
    //   } catch (err) {}
    // },
    async associatePopupConfirm(ids, list) {
      this.pageInfo.partner = ids.toString();
      this.pageInfo.partnerName = list.map(item => item.label).toString();
      if (this.pageInfo.supplementFlag && this.pageInfo.recordStatus === 1)
        return;
      try {
        const personIds = this.pageInfo.partner.toString();
        await startTask(this.pageInfo.id, personIds, this.userInfo.id);
        this.getTaskData();
      } catch (err) {
        console.log(err);
      }
    },
    topPathSwitch(idx) {
      const item = this.taskList[idx - 1];
      return item ? item.checked : false;
    },
    async punchCardClick() {
      // signType 1 nfc 2 二维码
      if (this.pageInfo.signType === 1) {
        this.nfcSign();
        return;
      }
      this.QRCodeSign();
    },
    punchCardClose() {
      this.showNFCPopup = false;
      window.NFCCallback = null;
    },
    nfcSign() {
      if (!window.android || !window.android.getNFCData) {
        this.$toast.fail("此版本不支持NFC打卡");
        return;
      }
      this.showNFCPopup = true;
      window.NFCCallback = async rfid => {
        if (!this.showNFCPopup) return; // nfc页面关闭则不再处理
        const params = {
          executePersonId: this.userInfo.id,
          orgCode: this.userInfo.orgCode,
          recordId: this.pageInfo.id,
          rfid
        };

        // 打卡失败提示，点位编码不在任务中
        let isValidate = await this.$refs.codeTip.check({
          recordId: params.recordId,
          type: "rfid",
          code: rfid
        });
        if (!isValidate) {
          this.$refs.codeTip.visible = true;
          return false;
        }

        const data = await NFCPunchCardSign(params);
        if (data?.isOverTime === 1) {
          return Toast("任务超期了");
        }
        const message = `<span>${data.pointName}</span><span style='float: right;'>${data.signTime}</span>`;
        this.$dialog
          .confirm({
            className: "custom-confirm-dialog",
            title: "打卡成功",
            message,
            messageAlign: "left",
            confirmButtonText: "确定",
            confirmButtonColor: "#1676ff",
            showCancelButton: false
          })
          .then(() => {
            this.setPunchCardSuccess(true);
            this.showNFCPopup = false;
            this.$router.push({
              name: "pointCheckTaskProject",
              params: {
                recordId: this.pageInfo.id,
                pointId: data.pointId,
                status: 1,
                signType: this.pageInfo.signType
              }
            });
          })
          .catch(() => {
            // on cancel
          });
      };
      window.androidH5.getNFCData("NFCCallback", "0");

      // this.showNFCPopup = true;
      // if (!this.showNFCPopup) return; // nfc页面关闭则不再处理
      // const params = {
      //   orgCode: this.userInfo.orgCode,
      //   recordId: this.pageInfo.id,
      //   rfid: "041A090D700000"
      // };
      // const data = await NFCPunchCardSign(params);
      // const message = `<span>${data.pointName}</span><span style='float: right;'>${data.signTime}</span>`;
      // this.$dialog
      //   .confirm({
      //     className: "custom-confirm-dialog",
      //     title: "打卡成功",
      //     message,
      //     messageAlign: "left",
      //     confirmButtonText: "确定",
      //     confirmButtonColor: "#1676ff",
      //     showCancelButton: false
      //   })
      //   .then(() => {
      //     this.setPunchCardSuccess(true);
      //     this.showNFCPopup = false;
      //     this.$router.push({
      //       name: "taskProject",
      //       params: {
      //         recordId: this.pageInfo.id,
      //         pointId: data.pointId,
      //         status: 1
      //       }
      //     });
      //   })
      //   .catch(() => {
      //     // on cancel
      //   });
    },
    QRCodeSign() {
      if (!window.android || !window.android.scanQrCode) {
        this.$toast.fail("此版本不支持二维码打卡");
        return;
      }
      window.QRCodeCallback = async (QRCodeInfo, base64, filename) => {
        const dateNow = new Date().toJSON();
        const file = this.base64toFile(
          base64,
          filename || `二维码${dateNow}.jpg`
        );
        const uploadResult = await this.uploadFile(file);
        const fileList = uploadResult || [];

        const params = {
          executePersonId: this.userInfo.id,
          orgCode: this.userInfo.orgCode,
          recordId: this.pageInfo.id,
          code: JSON.parse(QRCodeInfo).data,
          fileList
        };

        // 打卡失败提示，点位编码不在任务中
        let isValidate = await this.$refs.codeTip.check({
          recordId: params.recordId,
          type: "qrcode",
          code: params.code
        });
        if (!isValidate) {
          this.$refs.codeTip.visible = true;
          return false;
        }

        const data = await QRCodePunchCardSign(params);
        if (data?.isOverTime === 1) {
          return Toast("任务超期了");
        }
        const message = `<span>${data.pointName}</span><span style='float: right;'>${data.signTime}</span>`;
        this.$dialog
          .confirm({
            className: "custom-confirm-dialog",
            title: "打卡成功",
            message,
            messageAlign: "left",
            confirmButtonText: "确定",
            confirmButtonColor: "#1676ff",
            showCancelButton: false
          })
          .then(() => {
            this.setPunchCardSuccess(true);
            this.$router.push({
              name: "pointCheckTaskProject",
              params: {
                recordId: this.pageInfo.id,
                pointId: data.pointId,
                status: 1,
                signType: this.pageInfo.signType
              }
            });
          })
          .catch(() => {});
        window.QRCodeCallback = null;
      };
      window.androidH5.scanQrCode("QRCodeCallback", "1");
    },
    base64toFile(base64, filename) {
      let arr = base64.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    },
    uploadFile(file) {
      const filePath = window.globalConfig.VUE_APP_FILE_URL + "/file/upload";
      const fd = new FormData();
      fd.append("files", file);
      return axios.post(filePath, fd);
    },
    toDetail({ pointId }) {
      this.$router.push({
        name: "pointCheckTaskProject",
        params: {
          recordId: this.pageInfo.id,
          pointId: pointId,
          status: this.pageInfo.recordStatus,
          signType: this.pageInfo.signType
        }
      });
    },
    checkItemNumSwitch(num, startTime) {
      if (!startTime && !this.pageInfo.supplementTime) {
        return "--";
      }
      return num || 0;
    },
    switchAccountClick() {
      this.$dialog
        .confirm({
          className: "custom-confirm-dialog",
          title: "提醒",
          message: "确认退出当前账号？",
          messageAlign: "left",
          confirmButtonText: "确定",
          confirmButtonColor: "#1676ff"
        })
        .then(() => {
          // this.setRedirectURL(this.$route.fullPath);
          // window.sessionStorage.setItem("redirectURL", "/inspect-record");
          this.logout();
        })
        .catch(() => {
          // on cancel
        });
    },
    async makeUpClick() {
      const params = {
        recordId: this.pageInfo.id,
        executePersonId: this.userInfo.id
      };
      await recordSupplement(params);
      this.baseInfoPackUp = false;
      this.getTaskData();
    },
    afterRead(file, formFiles) {
      file = Array.isArray(file) ? file : [file];
      file.map(item => {
        item.status = "uploading";
        item.message = "上传中...";
        delete item.content;
        this.uploadFile(item.file)
          .then(res => {
            let res0 = res[0];
            item.status = "";
            item.message = "";

            item.url = this.getFileUrl(res0.id);
            item.name = res0.name;
            item.id = res0.id;
            item.size = res0.size;
            item.uploadTime = res0.uploadTime;
          })
          .catch(error => {
            item.status = "failed";
            item.message = "上传失败";
          });
      });
    },
    getFileUrl(id, downloadFlag = false) {
      return `${window.globalConfig.VUE_APP_FILE_URL}/file/download?downloadFlag=${downloadFlag}&fileName=${id}`;
    },
    associateClick() {
      if (this.pageInfo.supplementFlag && this.pageInfo.recordStatus === 1) {
        this.associatePopupVisible = true;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.content {
  padding: 74px 16px 16px;
  .title-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0 8px;
    .left-box {
      flex: 1;
    }
    .title {
      font-size: 16px;
      font-weight: 500;
      color: #2e2e4d;
      line-height: 22px;
      vertical-align: middle;
    }
    .overtime-tag {
      display: inline-block;
      height: 16px;
      line-height: 16px;
      font-size: 11px;
      padding: 0 6px;
      color: #fff;
      background-color: #f13030;
      text-align: center;
      border-radius: 3px;
      margin: 0 0 0 8px;
      vertical-align: middle;
    }
    .make-up-tag {
      display: inline-block;
      height: 16px;
      line-height: 16px;
      font-size: 11px;
      padding: 0 6px;
      color: #fff;
      background-color: #3683f4;
      text-align: center;
      border-radius: 3px;
      margin: 0 0 0 8px;
      vertical-align: middle;
    }
    .status-box {
      width: 44px;
      height: 16px;
      line-height: 16px;
      font-size: 11px;
      text-align: center;
      border: 1px solid;
      border-radius: 3px;
    }
    .status-box-weikaishi {
      color: #dea148;
      background: #ffecd1;
      border-color: #dea148;
    }
    .status-box-jinxingzhong {
      color: #3683f4;
      background: #e1edff;
      border-color: #3683f4;
    }
    .status-box-yiwancheng {
      color: #8eb433;
      background: #f5ffdc;
      border-color: #8eb433;
    }
    .status-box-yiguoqi {
      color: #5c7ba2;
      background: #e5eaf0;
      border-color: #5c7ba2;
    }
  }
  .date-box {
    display: inline-block;
    height: 16px;
    line-height: 16px;
    background: #eaeff5;
    border-radius: 2px;
    font-size: 11px;
    font-weight: 400;
    color: #3b4664;
    padding: 0 6px;
    margin: 0 0 16px;
  }
  .detail-box.pack-up {
    height: 100px;
  }
  .detail-box {
    margin: 0 0 8px;
    overflow: hidden;
    .detail-item {
      display: flex;
      align-items: center;
      padding: 0 0 8px;
      line-height: 20px;
      font-size: 14px;
      font-weight: 400;
      .label {
        color: #8c8f97;
      }
      .text {
        flex: 1;
        word-break: break-word;
        color: #2e2e4d;
      }
      .executor {
        flex: initial;
        // display: inline-block;
        padding: 2px 6px;
        background: #eaeff5;
        border-radius: 2px;
      }
      .associate {
        // border: 1px solid #c7c9d0;
        // padding: 2px 4px;
        .van-cell {
          border: 1px solid #c7c9d0;
          padding: 2px 4px;
        }
      }
      .switch-account {
        display: inline-block;
        font-size: 12px;
        margin: 0 10px 0;
        padding: 4px 6px 3px;
        background-color: #7f7f7f;
        border-radius: 15px;
        color: #fff;
      }
    }
    ::v-deep {
      .c-van-field::before {
        left: -8px;
      }
      .c-van-field {
        padding-top: 0;
        padding-left: 0;
        padding-right: 0;
        .c-label {
          font-size: 14px;
          color: #8c8f97;
        }
        .c-data-tiem {
          width: 100%;
          margin: 0;
        }
      }
    }
  }
  .exp-box {
    text-align: center;
  }
}
.list-box {
  min-height: calc(100vh - 301px);
  padding: 16px;
  background-color: rgba(236, 238, 242, 0.5);
  .item {
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0px 0px 8px 0px #dbdde4;
    font-size: 0;
    height: 86px;
    .index-box {
      display: inline-block;
      padding: 0 8px;
      text-align: center;
      z-index: 2;
      height: 100%;
      .path-box {
        font-size: 0;
        // height: 31px;
        height: calc(100% / 2 - 12px);
        .path {
          display: inline-block;
          // height: 31px;
          height: 100%;
          width: 3px;
          background-color: #eaeff5;
        }
        .checked-path {
          background-color: #1676ff;
        }
      }
      .index-radius {
        width: 24px;
        height: 24px;
        line-height: 24px;
        box-sizing: border-box;
        border-radius: 50%;
        font-size: 16px;
        font-weight: 500;
        color: #2e2e4d;
        background-color: #eaeff5;
      }
      .checked-radius {
        color: #ffffff;
        line-height: 25px;
        background-color: #1676ff;
      }
      .current-radius {
        line-height: 23px;
        background-color: #fff;
        border: 2.01px solid #1676ff;
      }
    }
    .task-info-box {
      font-size: 14px;
      display: inline-block;
      width: calc(100% - 44px);
      vertical-align: top;
      padding: 12px 4px 10px 0;
      overflow: hidden;
      .name-box {
        display: flex;
        justify-content: space-between;
        line-height: 20px;
        .name {
          display: inline-block;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          color: #2e2e4d;
        }
        .iconfont-Line {
          color: #aeb3c0;
        }
      }
      .date-box {
        font-size: 12px;
        font-weight: 400;
        color: #8c8f97;
        line-height: 17.01px;
        padding: 2px 0 8px;
      }
      .item-num-box {
        font-size: 12px;
        font-weight: 400;
        text-align: left;
        color: #8c8f97;
        height: 17.01px;
        line-height: 17.01px;
        .num-style {
          display: inline-block;
          overflow: hidden;
          white-space: nowrap;
        }
        .item-num {
          color: #2e2e4d;
        }
        .total {
          width: 97px;
        }
        .normal,
        .abnormal,
        .leakage {
          width: 67px;
        }
        .abnormal.active {
          label {
            background: rgba(241, 48, 48, 0.2);
            border-radius: 2px;
            color: #f13030;
            padding: 1px;
          }
          .item-num {
            color: #f13030;
          }
        }
        .leakage.active {
          label {
            background: rgba(233, 118, 23, 0.2);
            border-radius: 2px;
            color: #e97617;
            padding: 1px;
          }
          .item-num {
            color: #e97617;
          }
        }
      }
    }
  }
  .item-path {
    font-size: 0;
    height: 8.01px;
    margin: 0 0 0 18.5px;
    z-index: 2;
    .path {
      display: inline-block;
      width: 3px;
      // height: 8.01px;
      height: 100%;
      background-color: #eaeff5;
    }
    .checked-path {
      background-color: #1676ff;
    }
  }
}
.make-up-box {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 0 0 16px;
  text-align: center;
  .btn {
    display: inline-block;
    width: 100px;
    height: 44px;
    line-height: 44px;
    background: #1676ff;
    border-radius: 24px;
    font-size: 18px;
    font-weight: 500;
    color: #ffffff;
  }
}
</style>
